<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center">
                    <div class="card">
                        <div class="card-body table-responsive table-head pt-2">
                            <div class="row">
                                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                                    <h1 class="m-0 text-dark pt-2 text-left px-0">Member Landing Page</h1>
                                </div>
                                <div class="col-md-4 bor-bot pr-0">
                                    <div class="text-right pb-3 pt-1">
                                        <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                               <div class="col-md-12 p-0 memberdasbordsec mt-4 text-left">
                                   <div>
                                    <b-card no-body>
                                        <b-tabs pills card vertical>
                                          <b-tab title="Banner" active>
                                              <b-card-text>
                                                <form @submit.stop.prevent="handleSubmit($event, 'banner')">
                                                  <div class="row text-left">
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                      </div>
                                                      <div class="col-md-12 mt-4 imagesec pl-0">
                                                          <div class="rectimage">
                                                            <img ref="banner" :src="banner.preview" alt="aa" style="width: 100%;" />
                                                          <div class="atcmntbtn" :class="bannerErrors.has('image') ? 'is-invalid' : ''">
                                                            <b-form-file class="uploadImg" accept="image/png, image/jpeg" v-on:change="onImageChange($event, 'banner')" ></b-form-file>

                                                          </div>
                                                            <b-form-invalid-feedback :state="!bannerErrors.has('image')">
                                                              {{ bannerErrors.get("image") }}</b-form-invalid-feedback>
                                                          </div>
                                                          <p class="mt-3 mb-1 text-right notetext">Please upload 1920 X 1080 image</p>
                                                      </div>
                                                      <div class="col-md-2 mt-4 px-0">
                                                          <label>Banner Text</label><span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-5 mt-4">
                                                          <input class="form-control" :class="bannerErrors.has('title') ? 'is-invalid' : ''" v-model="form.banner.title" @keydown="bannerErrors.clear('title')" @input="cancelFunction('cancelButton1')">
                                                          <b-form-invalid-feedback :state="!bannerErrors.has('title')">
                                                          {{ bannerErrors.get("title") }}</b-form-invalid-feedback>
                                                      </div>
                                                      <div class="col-md-5 mt-4">
                                                      </div>
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" disabled @click="cancelForm($event,'banner')" id="cancelButton1" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                          <input type="submit" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4" value="Save">
                                                      </div>
                                                  </div>
                                                </form>
                                              </b-card-text>
                                          </b-tab>
                                          <b-tab title="About Section">
                                              <b-card-text>
                                                <form @submit.stop.prevent="handleSubmit($event, 'about')">
                                                 <div class="row text-left lanpagaboutsec">
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                      </div>
                                                       <div class="col-md-12 mt-4 imagesec pl-0">
                                                         <div class="col-md-5 rectimage">
                                                           <img ref="about" :src="about.preview" alt="aa" style="width: 100%;" />
                                                           <div class="atcmntbtn">
                                                             <b-form-file class="uploadImg" accept="image/png, image/jpeg" v-on:change="onImageChange($event, 'about')" :state="!aboutErrors.has('image')"></b-form-file>
                                                           </div>
                                                           <b-form-invalid-feedback :state="!aboutErrors.has('image')">
                                                             {{ aboutErrors.get("image") }}</b-form-invalid-feedback>
                                                         </div>
                                                          <p class="mt-3 mb-1 text-right notetext">Please upload 430 X 450 image</p>
                                                      </div>
                                                      <div class="col-md-1 mt-4 px-0">
                                                          <label>Heading</label>
                                                        <span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-5 mt-4">
                                                          <input class="form-control" :class="aboutErrors.has('title') ? 'is-invalid' : ''" v-model="form.about.title" @keydown="aboutErrors.clear('title')" @input="cancelFunction('cancelButton2')">
                                                        <b-form-invalid-feedback :state="!aboutErrors.has('title')">
                                                          {{ aboutErrors.get("title") }}</b-form-invalid-feedback>
                                                      </div>
                                                      <div class="col-md-6 mt-4">
                                                      </div>
                                                      <div class="col-md-1 mt-4 px-0">
                                                          <label>Text</label>
                                                        <span v-b-tooltip.hover title="Maximum 200 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-11 mt-4">
                                                          <textarea rows="4" :class="aboutErrors.has('content') ? 'is-invalid form-control' : 'form-control'" v-model="form.about.content" @keydown="aboutErrors.clear('content')" @input="cancelFunction('cancelButton2')"></textarea>
                                                          <b-form-invalid-feedback :state="!aboutErrors.has('content')">
                                                          {{ aboutErrors.get("content") }}</b-form-invalid-feedback>
                                                      </div>

                                                     <template v-for="(row, index) in aboutContentList">
                                                       <div :key="'label_'+index" class="col-md-1 mt-4 px-0">
                                                         <label v-if="index==0">Benefits</label>
                                                       </div>
                                                        <div :key="'txt_'+index" class="col-md-7 mt-4 upldusericonsec">
                                                          <div class="row">
                                                          <div class="col-md-5"> <input placeholder="ex: fa fa-user" :class="aboutErrors.has('content_list.'+index+'.icon') ? 'is-invalid form-control mr-2' : 'form-control mr-2'" v-model="row.icon" @keydown="aboutErrors.clear('content_list.'+index+'.icon')" @input="cancelFunction('cancelButton2')">
                                                            <b-form-invalid-feedback
                                                                :state="!aboutErrors.has('content_list.'+index+'.icon')"
                                                            >
                                        {{ aboutErrors.get('content_list.'+index+'.icon') }}</b-form-invalid-feedback>
                                                          </div>
                                                            <div class="col-md-7"> <input :class="aboutErrors.has('content_list.'+index+'.text') ? 'is-invalid form-control repeter-text mr-2' : 'form-control repeter-text mr-2'" v-model="row.text" @keydown="aboutErrors.clear('content_list.'+index+'.text')" @input="cancelFunction('cancelButton2')">
                                                          <b-form-invalid-feedback
                                                              :state="!aboutErrors.has('content_list.'+index+'.text')"
                                                          >
                                        {{ aboutErrors.get('content_list.'+index+'.text') }}</b-form-invalid-feedback>
                                                          </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4 mt-4" :key="'action_'+index">
                                                            <span :key="'button_'+index" class="pencillh cuscolorred ml-3"><a @click="removeElement(index,'about')"><i class="fas fa-trash"></i></a></span>
                                                        </div>
                                                     </template>

                                                      <div class="col-md-5 mt-4 upldusericonsec text-right">
                                                        <button class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-2" @click="addRow($event, 'about')">Add New</button>
                                                      </div>
                                                      <div class="col-md-6 mt-4">
                                                      </div>
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" id="cancelButton2" disabled @click="cancelForm($event,'about')" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                          <input type="submit" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4" value="Save">
                                                      </div>
                                                  </div>
                                                </form>
                                              </b-card-text>
                                          </b-tab>
                                          <b-tab title="Join Today Section">
                                              <b-card-text>
                                                <form @submit.stop.prevent="handleSubmit($event, 'joinToday')">
                                                 <div class="row text-left lanpagaboutsec">
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                      </div>
                                                      <div class="col-md-1 mt-4 px-0">
                                                          <label>Heading</label>
                                                        <span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-5 mt-4">
                                                        <input class="form-control" :class="joinTodayErrors.has('title') ? 'is-invalid' : ''"  v-model="form.joinToday.title" @keydown="joinTodayErrors.clear('title')" @input="cancelFunction('cancelButton3')">
                                                        <b-form-invalid-feedback :state="!joinTodayErrors.has('title')">
                                                          {{ joinTodayErrors.get("title") }}</b-form-invalid-feedback>
                                                      </div>
                                                      <div class="col-md-6 mt-4">
                                                      </div>
                                                      <div class="col-md-1 mt-4 px-0">
                                                          <label>Text</label>
                                                        <span v-b-tooltip.hover title="Maximum 200 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-11 mt-4">
                                                          <textarea rows="3" :class="joinTodayErrors.has('content') ? 'is-invalid form-control' : 'form-control'"  v-model="form.joinToday.content" @keydown="joinTodayErrors.clear('content')" @input="cancelFunction('cancelButton3')"></textarea>
                                                          <b-form-invalid-feedback :state="!joinTodayErrors.has('content')">
                                                          {{ joinTodayErrors.get("content") }}</b-form-invalid-feedback>
                                                      </div>
                                                   <div class="col-md-12 mt-4 px-0">
                                                     <label>Other Info</label>
                                                   </div>
                                                     <template v-for="(row, index) in joinTodayContentList">

                                                       <div class="row" :key="'jrow_'+index">
                                                        <div :key="'jtxt_'+index" class="col-md-6 mt-4">
                                                          <div class="row">
                                                            <div class="col-md-5">
                                                              <input placeholder="ex: fa fa-user" :class="joinTodayErrors.has('content_list.'+index+'.icon') ? 'form-control repeter-icon mr-2' : 'form-control repeter-icon mr-2'" v-model="row.icon" @keydown="joinTodayErrors.clear('content_list.'+index+'.icon')" @input="cancelFunction('cancelButton3')">
                                                              <span class="d-block invalid-feedback"  v-if="joinTodayErrors.has('content_list.'+index+'.icon')">
                                                           {{ joinTodayErrors.get('content_list.'+index+'.icon') }}</span>
                                                            </div>
                                                            <div class="col-md-7">
                                                              <input maxlength="50" :class="joinTodayErrors.has('content_list.'+index+'.text') ? 'form-control repeter-text' : 'form-control repeter-text'" v-model="row.text" @keydown="joinTodayErrors.clear('content_list.'+index+'.text')" @input="cancelFunction('cancelButton3')">
                                                          <span class="d-block invalid-feedback" v-if="joinTodayErrors.has('content_list.'+index+'.text')">
                                                           {{ joinTodayErrors.get('content_list.'+index+'.text') }}</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                         <div class="col-md-6 mt-4">
                                                           <div class="row">
                                                       <div :key="'jinfoiocn_'+index" class="col-md-1" v-b-tooltip.hover title="Maximum 50 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </div>
                                                       <div :key="'jcon_'+index" class="col-md-8 pl-0">
                                                            <input maxlength="200" :class="joinTodayErrors.has('content_list.'+index+'.description') ? 'form-control' : 'form-control'" v-model="row.description" @keydown="joinTodayErrors.clear('content_list.'+index+'.description')" @input="cancelFunction('cancelButton3')">
                                                         <span class="d-block invalid-feedback" v-if="joinTodayErrors.has('content_list.'+index+'.description')">
                                                            {{ joinTodayErrors.get('content_list.'+index+'.description') }}</span>
                                                        </div>
                                                         <div :key="'jinfoiocn_'+index" class="col-md-1" v-b-tooltip.hover title="Maximum 200 characters">
                                                           <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                         </div>
                                                        <div :key="'jaction_'+index" class="col-md-2">
                                                            <span class="pencillh cuscolorred ml-3"><a @click="removeElement(index,'joinToday')"><i class="fas fa-trash"></i></a></span>
                                                        </div>
                                                           </div>
                                                         </div>
                                                        <div :key="'jrow_'+index" class="col-md-1 mt-4 px-0">
                                                        </div>
                                                       <div :key="'jerrcon_'+index" class="col-md-5 mt-4 pl-0">

                                                       </div>
                                                       <div :key="'erraction_'+index" class="col-md-2 mt-4">
                                                       </div>
                                                       </div>
                                                     </template>
                                                      <div class="col-md-4 mt-4 upldusericonsec">
                                                      </div>
                                                      <div class="col-md-5 mt-4 pl-0 text-right">
                                                          <button type="button" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-2" @click="addRow($event, 'joinToday')">Add New</button>
                                                      </div>
                                                      <div class="col-md-2 mt-4">
                                                      </div>
                                                      <div class="col-md-12 text-right" style="margin-top: 100px;">
                                                          <button type="button" id="cancelButton3" disabled @click="cancelForm($event,'joinToday')" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                          <input type="submit" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4" value="Save">
                                                      </div>
                                                  </div>
                                                </form>
                                              </b-card-text>
                                          </b-tab>
                                          <b-tab title="Video">
                                              <b-card-text>
                                                <form @submit.stop.prevent="handleSubmit($event, 'video')">
                                                 <div class="row text-left">
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                      </div>
                                                      <div class="col-md-1 px-0">
                                                          <label class="font13">Heading</label>
                                                        <span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                      </div>
                                                      <div class="col-md-5">
                                                          <input class="form-control" :class="videoErrors.has('title') ? 'is-invalid' : ''" v-model="form.video.title" @keydown="videoErrors.clear('title')" @input="cancelFunction('cancelButton4')">
                                                          <b-form-invalid-feedback :state="!videoErrors.has('title')">
                                                          {{ videoErrors.get("title") }}</b-form-invalid-feedback>
                                                      </div>
                                                      <div class="col-md-6">
                                                      </div>
                                                      <div class="col-md-1 mt-4 px-0">
                                                          <label class="font13 m-0">Video URL</label>
                                                      </div>
                                                      <div class="col-md-5 mt-4">
                                                          <input class="form-control" :class="videoErrors.has('image') ? 'is-invalid' : ''" v-model="form.video.image" @keydown="videoErrors.clear('image')" @input="cancelFunction('cancelButton4')">
                                                          <b-form-invalid-feedback :state="!videoErrors.has('image')">
                                                          {{ videoErrors.get("image") }}</b-form-invalid-feedback>
                                                         <div class="text-red" v-if="youtubeUrlError">
                                                          {{ youtubeUrlError }}</div>
                                                      </div>
                                                      <div class="col-md-6 mt-4">
                                                      </div>
                                                      <div class="col-md-12 text-right mt-4">
                                                          <button type="button" id="cancelButton4" disabled @click="cancelForm($event,'video')" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                          <input type="submit" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4" value="Save">
                                                      </div>
                                                  </div>
                                                </form>
                                              </b-card-text>
                                          </b-tab>
                                          <b-tab title="Sponsors">
                                            <b-card-text>
                                              <form @submit.stop.prevent="handleSubmit($event, 'sponsors')">
                                               <div class="row text-left sponsorssec">
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                    </div>
                                                    <div class="col-md-1 px-0">
                                                        <label class="font13">Heading</label>
                                                      <span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input class="form-control" :class="sponsorsErrors.has('title') ? 'is-invalid' : ''" v-model="form.sponsors.title" @keydown="sponsorsErrors.clear('title')" @input="cancelFunction('cancelButton5')">
                                                        <b-form-invalid-feedback :state="!sponsorsErrors.has('title')">
                                                        {{ sponsorsErrors.get("title") }}</b-form-invalid-feedback>
                                                    </div>
                                                    <div class="col-md-7">
                                                    </div>
                                                    <div class="col-md-1 mt-4 px-0">
                                                        <label class="font13 m-0">Upload Logo</label>
                                                    </div>
                                                    <template v-for="(row, index) in sponsorsContentList">
                                                      <div :key="'url_'+index" class="col-md-2 mt-4 prvimgsec pr-0">
                                                          <img :src="row.url" alt="image"/>
                                                          <div class="apslsctions text-right">
                                                          <span class="pencillh cuscolorred ml-1"><a @click="removeElement(index,'sponsors')"><i class="fas fa-trash"></i></a></span>
                                                          </div>
                                                      </div>
                                                    </template>
                                                    <div class="col-md-3 mt-4 sponsersecuplbtn">
                                                        <div class="atcmntbtn1 col-md-9">
                                                          <input type="file" name="files" ref="file" multiple="multiple" accept="image/png, image/jpeg" @change="addRow($event,'sponsors')" />
                                                        </div>
                                                      <div class="text-red" v-if="sponsorsImageError">
                                                        {{ sponsorsImageError }}</div>
                                                    </div>

                                                    <div class="col-md-6 mt-4">
                                                    </div>
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" id="cancelButton5" disabled @click="cancelForm($event,'sponsors')" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                      <input type="submit" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4" value="Save">
                                                    </div>
                                                </div>
                                              </form>
                                            </b-card-text>
                                          </b-tab>
                                        </b-tabs>
                                    </b-card>
                                    </div> 
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <b-modal id="modal-previewcenter" size="xl" title="" hide-footer="true" :modal-class="mobileclass">
            <b-tabs content-class="mt-3">
              <b-tab title="Web View" active>
                <div class="row">
                  <div class="col-md-12 webview">
                    <iframe :src="iframe.src" title="W3Schools Free Online Web Tutorials"></iframe>
                  </div>
                </div>
              </b-tab>
                <b-tab title="Mobile View">
                    <div class="row">
                        <div class="col-md-12 mobview">
                            <iframe :src="iframe.src" title="W3Schools Free Online Web Tutorials"></iframe>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>

    </section>
    
</template>

<script>
  import axios from "axios";
  import CustomAlert from "../CustomAlert";
  import Errors from "../../Errors";
  import $ from 'jquery';

  export default {
    name:"Landing Page",
    components:{
      CustomAlert
    },
    data() {
      return {
        mobileclass: 'previewpopupmobilesec',
        bannerErrors: new Errors(),
        aboutErrors: new Errors(),
        joinTodayErrors: new Errors(),
        videoErrors: new Errors(),
        sponsorsErrors: new Errors(),
        iframe: {
            src: '/'
        },
        sections:[],
        form: {
          banner: {
            image:'',
            title:'',
            banner_img:''
          },
          about: {
            title:'',
            content:'',
            banner_img:'',
            image:''
          },
          joinToday:{
            title:'',
            content:''
          },
          video:{
            image:'',
            title:''
          },
          sponsors:{
            title:''
          }
        },
        banner:{preview:'/img/mdrectangleimage.f47903fa.svg'},
        about:{preview:'/img/mdrectangleimage.f47903fa.svg'},
        alertMessage: "",
        displayAlert: false,
        aboutContentList: [],
        joinTodayContentList: [],
        sponsorsContentList: [],
        showLoader:false,
        resetForm:[],
        ResetAboutContentList:[],
        resetJoinTodayContentList:[],
        resetSponsorsContentList:[],
        youtubeUrlError:'',
        sponsorsImageError:''
      }
    },
    methods:{
      loadBannerSection(){
        axios.get(process.env.VUE_APP_URL + "api/cms/section/2/1",  {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                  this.form.banner = response.data.data;
                  this.banner.preview = process.env.VUE_APP_URL + response.data.data.image;
                  this.form.banner.banner_img = response.data.data.image;
                  this.form.banner.image = '';
                  this.resetForm.bannerTitle = response.data.data.title;
                  this.resetForm.bannerPreview = this.banner.preview;

              }
            }).catch((error) => {
          console.log(error.response.data.errors);
        });
      },
      loadAboutSection(){
        axios.get(process.env.VUE_APP_URL + "api/cms/section/2/2",  {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                this.aboutContentList = [];
                this.ResetAboutContentList = [];
                this.form.about = response.data.data;
                this.about.preview = process.env.VUE_APP_URL+response.data.data.image;
                this.form.about.banner_img = response.data.data.image;
                this.form.about.image = '';
                this.resetForm.aboutTitle = response.data.data.title;
                this.resetForm.aboutContent = response.data.data.content;
                this.resetForm.preview = this.about.preview;
                let content_list = response.data.data.contentlist;
                if(content_list.length){
                  content_list.forEach(conlist => {
                    this.aboutContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.title, description: conlist.description });
                  });
                  content_list.forEach(conlist => {
                    this.ResetAboutContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.title, description: conlist.description });
                  });
                }


              }
            }).catch((error) => {
          console.log(error.response.data.errors);
        });
      },
      loadJoinTodaySection(){
        axios.get(process.env.VUE_APP_URL + "api/cms/section/2/3",  {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                this.joinTodayContentList = [];
                this.resetJoinTodayContentList = [];
                this.form.joinToday = response.data.data;
                this.resetForm.joinTodayTitle = response.data.data.title;
                this.resetForm.joinTodayContent = response.data.data.content;
                let content_list = response.data.data.contentlist;
                if(content_list.length ){
                  content_list.forEach(conlist => {
                    this.joinTodayContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.title, description: conlist.description });
                  });
                  content_list.forEach(conlist => {
                    this.resetJoinTodayContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.title, description: conlist.description });
                  });
                }
              }
            }).catch((error) => {
          console.log(error.response.data.errors);
        });
      },
      loadVideoSection(){
        axios.get(process.env.VUE_APP_URL + "api/cms/section/2/4",  {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                this.form.video = response.data.data;
                this.resetForm.videoTitle = response.data.data.title;
                this.resetForm.videoImg = response.data.data.banner_img;
              }
            }).catch((error) => {
          console.log(error.response.data.errors);
        });
      },
      loadSponsorsSection(){
        axios.get(process.env.VUE_APP_URL + "api/cms/section/2/5",  {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                this.sponsorsContentList = [];
                this.resetSponsorsContentList = [];
                this.form.sponsors = response.data.data;
                this.resetForm.sponsorsTitle = response.data.data.title;
                let content_list = response.data.data.contentlist;
                if(content_list.length ){
                  content_list.forEach((conList, i) => {
                    this.sponsorsContentList.push({ id: conList.id, icon: conList.icon, text: conList.title, description: conList.description,url: process.env.VUE_APP_URL+conList.url, image: conList.url});
                  });
                  content_list.forEach((conList, i) => {
                    this.resetSponsorsContentList.push({ id: conList.id, icon: conList.icon, text: conList.title, description: conList.description,url: process.env.VUE_APP_URL+conList.url, image: conList.url});
                  });
                }
                this.preview='';
              }
            }).catch((error) => {
          console.log(error.response.data.errors);
        });
      },
      onImageChange(e, section){
        let file = e.target.files[0];
        if(section === 'banner')
        {
          this.form.banner.image = file;
          this.banner.preview = URL.createObjectURL(file);
          this.bannerErrors.clear('image');
          $('#cancelButton1').removeAttr("disabled");
        }
        else if(section === 'about')
        {
          this.form.about.image = file;
          this.about.preview = URL.createObjectURL(file);
          this.aboutErrors.clear('image');
          $('#cancelButton2').removeAttr("disabled");
        }
      },
      handleSubmit(e, section) {
        e.preventDefault();
        this.showLoader = true;
        this.sponsorsImageError='';
        console.log("this.form.banner.title", this.form.banner.title);
        this.$set(this.adminHeaders, 'content-type', 'multipart/form-data');
        let formData = new FormData();
        if(section === 'banner') {
          formData.append('image', this.form.banner.image);
          formData.append('title', this.form.banner.title);
          formData.append('banner_img', this.form.banner.banner_img);
          formData.append('page_id', 2);
          formData.append('section_id', 1);
        }
        else if(section === 'about') {
          formData.append('image', this.form.about.image);
          formData.append('title', this.form.about.title);
          formData.append('content', this.form.about.content);
          formData.append('banner_img', this.form.about.banner_img);
          formData.append('page_id', 2);
          formData.append('section_id', 2);
          formData.append('content_list', JSON.stringify(this.aboutContentList));
        }
        else if(section === 'joinToday') {
          formData.append('title', this.form.joinToday.title);
          formData.append('content', this.form.joinToday.content);
          formData.append('banner_img', this.form.joinToday.image);
          formData.append('page_id', 2);
          formData.append('section_id', 3);
          formData.append('content_list', JSON.stringify(this.joinTodayContentList));
        }
        else if(section === 'video') {
          formData.append('title', this.form.video.title);
          formData.append('banner_img', this.form.video.image);
          formData.append('page_id', 2);
          formData.append('section_id', 4);
        }
        else if(section === 'sponsors') {
          formData.append('title', this.form.sponsors.title);
          formData.append('banner_img', this.form.sponsors.image);
          formData.append('page_id', 2);
          formData.append('section_id', 5);

          this.sponsorsContentList.forEach((file, index)=>{
            console.log('file', file)
            formData.append('content_list['+index+'][id]', (file.id) ?? '');
            formData.append('content_list['+index+'][url]', file.url);
            formData.append('content_list['+index+'][image]', file.image);
          });
        }

        axios.post(process.env.VUE_APP_URL + "api/cms/section", formData, {headers: this.adminHeaders}, )
            .then((response) => {
              if (response.data.status === 'success') {
                this.showLoader = false;
                this.youtubeUrlError = '';
                if(response.data.data.section_id == 1) {
                  this.form.banner = response.data.data;
                  this.loadBannerSection();
                }
                else if(response.data.data.section_id == 2) {
                  this.form.about = response.data.data;
                  this.loadAboutSection();
                }
                else if(response.data.data.section_id == 3) {
                  this.form.joinToday = response.data.data;
                  this.loadJoinTodaySection();
                }
                else if(response.data.data.section_id == 4) {
                  this.form.video = response.data.data;
                  this.loadVideoSection();
                }
                else if(response.data.data.section_id == 5) {
                  this.sponsorsErrors = new Errors();
                  this.form.sponsors = response.data.data;
                  this.loadSponsorsSection();
                }
                this.displayAlert = true;
                this.alertMessage = response.data.message;
              }else{
                this.showLoader = false;
                this.youtubeUrlError = response.data.message;
              }
            }).catch((error) => {
            this.showLoader = false;
            this.onFail(error.response.data.errors, section);
          });
      },
      addRow: function(e,section) {
        e.preventDefault();
        let array = {id: "",icon: "",text: "",description: ""};
        if(section === 'about') {
          this.aboutContentList.push(array);
          $('#cancelButton2').removeAttr('disabled');
        }
        if(section === 'joinToday')
        {
          this.joinTodayContentList.push(array);
          $('#cancelButton3').removeAttr('disabled');
        }
        if(section === 'sponsors')
        {
          this.sponsorsImageError='';
          const uploadedfiles = this.$refs.file.files;
          console.log("file", uploadedfiles);
          if(uploadedfiles[0].type.match('image.*')==null) {
            this.sponsorsImageError = 'Please insert image only';
            return false;
          }
          for( var i = 0; i < this.$refs.file.files.length; i++ ){
            let file = this.$refs.file.files[i];
            this.sponsorsContentList.push({url:URL.createObjectURL(file), image:file});
          }
          $('#cancelButton5').removeAttr('disabled');
        }
      },
      removeElement: function(index, section) {
        if(section === 'about')
        {
          this.aboutContentList.splice(index, 1);
          $('#cancelButton2').removeAttr('disabled');
        }
        else if(section === 'joinToday')
        {
          this.joinTodayContentList.splice(index, 1);
          $('#cancelButton3').removeAttr('disabled');
        }
        else if(section === 'sponsors')
        {
          this.sponsorsContentList.splice(index, 1);
          $('#cancelButton5').removeAttr('disabled');
        }
      },
      cancelForm(e,section) {
        if(section === 'banner')
        {
          this.form.banner.title = this.resetForm.bannerTitle;
          this.form.banner.banner_img = new URL(this.resetForm.bannerPreview).pathname.replace('//','/');
          this.form.banner.image = '';
          this.banner.preview = this.resetForm.bannerPreview;
          $('#cancelButton1').attr('disabled', true);
        }
        if(section === 'about')
        {
          this.about.preview = this.resetForm.preview;
          this.form.about.banner_img = new URL(this.resetForm.preview).pathname.replace('//','/');
          this.form.about.title = this.resetForm.aboutTitle;
          this.form.about.content = this.resetForm.aboutContent;
          this.form.about.image = '';
          this.aboutContentList = [];
          this.ResetAboutContentList.forEach(conlist => {
            this.aboutContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.text, description: conlist.description });
          });
          $('#cancelButton2').attr('disabled', true);
        }
        if(section === 'joinToday')
        {
          this.form.joinToday.title = this.resetForm.joinTodayTitle;
          this.form.joinToday.content = this.resetForm.joinTodayContent;
          this.joinTodayContentList = [];
          this.resetJoinTodayContentList.forEach(conlist => {
            this.joinTodayContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.text, description: conlist.description });
          });
          $('#cancelButton3').attr('disabled', true);
        }
        if(section === 'video')
        {
          this.form.video.title = this.resetForm.videoTitle;
          this.form.video.banner_img = this.resetForm.videoImg;
          $('#cancelButton4').attr('disabled', true);
        }
        if(section === 'sponsors')
        {
          this.sponsorsContentList = [];
          this.resetSponsorsContentList.forEach(conlist => {
            this.sponsorsContentList.push({ id: conlist.id, icon: conlist.icon, text: conlist.text, description: conlist.description });
          });
          this.form.sponsors.title = this.resetForm.sponsorsTitle;
          $('#cancelButton5').attr('disabled', true);
        }
      },
      onFail(errors, section) {
        if(section === 'banner') {
          this.bannerErrors.record(errors);
        }
        if(section === 'about') {
          this.aboutErrors.record(errors);
        }
        if(section === 'joinToday') {
          this.joinTodayErrors.record(errors);
        }
        if(section === 'video') {
          this.videoErrors.record(errors);
        }
        if(section === 'sponsors') {
          this.sponsorsErrors.record(errors);
        }
      },
      cancelFunction(id){
        $('#'+id).removeAttr("disabled")
      }
    },
    mounted(){
      this.loadBannerSection();
      this.loadAboutSection();
      this.loadJoinTodaySection();
      this.loadVideoSection();
      this.loadSponsorsSection();
    }
  }
</script>